import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [userDetails, setUserDetails] = useState(null);

  const fetchUserDetails = async () => {
    try {
      //console.log('Calling userDetails endpoint'); // Add log here
      const res = await axios.get('https://dev.yapper.co:5000/api/userDetails', { withCredentials: true });
      setUserDetails(res.data.user);
    } catch (err) {
      setTimeout(console.error.bind(console, "Fetching user details failed:", err));
    }
  };

  useEffect(() => {
    axios.get('https://dev.yapper.co:5000/api/checkSession', { withCredentials: true })
      .then((res) => {
        setIsAuthenticated(true);
        setUser(res.data.user);
        fetchUserDetails();
      })
      .catch((err) => {
        setTimeout(console.error.bind(console, "Session check failed:", err));
        setIsAuthenticated(false);
        setUser(null); // Ensure user is set to null
        setUserDetails(null); // Ensure userDetails is set to null
      })
      .finally(() => {
        setLoading(false); // Set loading to false after session check completes
      }); 
  }, []);

  useEffect(() => {
    setTimeout(console.log.bind(console, 'isAuthenticated:', isAuthenticated)); // Log authentication state
  }, [isAuthenticated, user]);

  const login = async (email, password) => {
    setLoading(true); // Set loading to true when login starts
    try {
      const res = await axios.post('https://dev.yapper.co:5000/api/login', { email, password }, { withCredentials: true });
      setIsAuthenticated(true);
      setUser(res.data.user);
      await fetchUserDetails();
    } catch (err) {
      setTimeout(console.error.bind(console, "Login failed:", err));
      setIsAuthenticated(false);
      setUser(null); // Ensure user is set to null
      setUserDetails(null); // Ensure userDetails is set to null
    } finally {
      setLoading(false); // Set loading to false after login completes
    }
  };

  const logout = async () => {
    setLoading(true); // Set loading to true when logout starts
    try {
      await axios.post('https://dev.yapper.co:5000/api/logout', {}, { withCredentials: true });
      setIsAuthenticated(false);
      setUser(null);
      setUserDetails(null); // Ensure userDetails is set to null
    } catch (err) {
      setTimeout(console.error.bind(console, "Logout failed:", err));
    } finally {
      setLoading(false); // Set loading to false after logout completes
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, userDetails, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
