// src/pages/LoginPage.js
import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const LoginPage = () => {
  const { login, error } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(email, password);
    if (!error) {
      navigate('/chats');
    }
  };

  return (
    <>
      <Helmet>
        <title>Login - Yapper</title>
      </Helmet>
      <div className="container">
        <div className="row align-items-center justify-content-center min-vh-100 gx-0">
          <div className="col-12 col-md-5 col-lg-4">
            <div className="card card-shadow border-0">
              <div className="card-body">
                <div className="row g-6">
                  <div className="col-12">
                    <div className="text-center">
                      <h3 className="fw-bold mb-2">Sign In</h3>
                      <p>Login to your account</p>
                    </div>
                  </div>
                  {error && (
                    <div className="col-12">
                      <div className="alert alert-danger">{error}</div>
                    </div>
                  )}
                  <form onSubmit={handleSubmit}>
                    <div className="col-12">
                      <div className="form-floating">
                        <input style={{ marginTop: '1.25rem' }}
                          type="email"
                          name="email"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label htmlFor="email">Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          style={{ marginTop: '1.25rem' }}
                          type="password"
                          name="password"
                          className="form-control"
                          id="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <label htmlFor="password">Password</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        style={{ marginTop: '1.25rem' }}
                        className="btn btn-block btn-lg btn-primary w-100"
                        type="submit"
                      >
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* Text */}
            <div className="text-center mt-8">
              <p>
                Don't have an account yet? <a href="#">Sign up</a>
              </p>
              <p>
                <a href="#">Forgot Password?</a>
              </p>
            </div>
          </div>
        </div>
        {/* / .row */}
      </div>
    </>
  );
};

export default LoginPage;
