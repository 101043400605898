// src/pages/HomePage.js
import React from 'react';
import { Helmet } from 'react-helmet';

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Yapper - Group Chats and More</title>
      </Helmet>
      <div className="welcome">
        <h2>YAPPER</h2>
      </div>
    </>
  );
};

export default HomePage;
