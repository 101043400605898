import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import ChatsPage from './pages/ChatsPage';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';

function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

function loadStyles() {
  const colorScheme = localStorage.getItem('color-scheme') || 'light';
  const lightLink = document.createElement('link');
  const darkLink = document.createElement('link');

  lightLink.rel = 'stylesheet';
  lightLink.href = `${process.env.PUBLIC_URL}/assets/css/template.bundle.css`;
  lightLink.className = 'css-lt';
  lightLink.media = 'not all';

  darkLink.rel = 'stylesheet';
  darkLink.href = `${process.env.PUBLIC_URL}/assets/css/template.dark.bundle.css`;
  darkLink.className = 'css-dk';
  darkLink.media = 'not all';

  document.head.appendChild(lightLink);
  document.head.appendChild(darkLink);

  if (colorScheme === 'light') {
    lightLink.media = 'all';
  } else {
    darkLink.media = 'all';
  }
}

function App() {
  useEffect(() => {
    loadScript(`${process.env.PUBLIC_URL}/assets/js/vendor.js`)
      .then(() => loadScript(`${process.env.PUBLIC_URL}/assets/js/template.js`))
      .catch((error) => console.error('Error loading scripts:', error));

    loadStyles();
  }, []);

  return (
    <AuthProvider>
      <Router>
        <MainLayout />
      </Router>
    </AuthProvider>
  );
}

const MainLayout = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const isLoginPage = location.pathname === '/login';
  const isChatsPage = location.pathname.startsWith('/chats');

  return (
    <div className="layout overflow-hidden">
      {isChatsPage && <Navbar />}
      {isChatsPage && <Sidebar />}
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/chats" element={<ProtectedRoute element={ChatsPage} />} />
        <Route path="/chats/:chatId" element={<ProtectedRoute element={ChatsPage} />} />
      </Routes>
    </div>
  );
};

setTimeout(console.log.bind(console, '%cHold Up!', 'color: red; font-size: 60px; font-weight: bold;'));
setTimeout(console.log.bind(console, '%cIf someone told you to copy/paste something here you have an 11/10 chance you\'re being scammed.', 'color: white; font-size: 18px;'));
setTimeout(console.log.bind(console, '%cPasting anything in here could give attackers access to your account.', 'color: red; font-size: 22px; font-weight: bold;'));
setTimeout(console.log.bind(console, '%cUnless you understand exactly what you are doing, close this window and stay safe.', 'color: white; font-size: 18px;'));
setTimeout(console.log.bind(console, '%cIf you do understand exactly what you are doing, you should come work with us %chttps://yapper.co/jobs', 'color: white; font-size: 18px;', 'color: blue; font-size: 16px; text-decoration: underline;'));


export default App;

