import React, { useEffect, useState } from 'react';
import { useAuth } from './context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Sidebar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const res = await axios.get('https://dev.yapper.co:5000/api/userChats', { withCredentials: true });
        setChats(res.data);
      } catch (err) {
        console.error('Error fetching chats:', err);
      } finally {
        setIsLoading(false); // Set loading to false after fetching
      }
    };

    if (user) {
      fetchChats();
    }
  }, [user]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const formatTimestamp = (timestamp) => {
    const localTime = new Date(timestamp);
    const now = new Date();
    const diffInSeconds = Math.floor((now - localTime) / 1000);

    if (isNaN(localTime.getTime())) {
      return 'Invalid Date';
    }

    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      return `${diffInMinutes} minutes ago`;
    } else if (diffInSeconds < 86400) { // Less than a day
      const diffInHours = Math.floor(diffInSeconds / 3600);
      return `${diffInHours} hours ago`;
    } else {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      if (localTime > yesterday) {
        return 'Yesterday';
      } else {
        const dayOfWeek = localTime.toLocaleDateString(undefined, { weekday: 'long' });
        const startOfWeek = new Date(now);
        startOfWeek.setDate(now.getDate() - now.getDay());
        startOfWeek.setHours(0, 0, 0, 0);

        if (localTime >= startOfWeek) {
          return dayOfWeek;
        } else {
          return localTime.toLocaleDateString(undefined, {
            month: 'short',
            day: 'numeric'
          });
        }
      }
    }
  };

  const renderPlaceholders = (count) => {
    return Array.from({ length: count }).map((_, index) => (
      <a href="#" key={index} className="card border-0 text-reset">
        <div className="card-body">
          <div className="row gx-5">
            <div className="col-auto">
              <div className="avatar">
                <svg
                  className="avatar-img placeholder-img"
                  width="100%"
                  height="100%"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <title>Placeholder</title>
                  <rect width="100%" height="100%" fill="#868e96"></rect>
                </svg>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center mb-3">
                <h5 className="placeholder-glow w-100 mb-0">
                  <span className="placeholder col-5"></span>
                </h5>
              </div>
              <div className="placeholder-glow">
                <span className="placeholder col-12"></span>
                <span className="placeholder col-8"></span>
              </div>
            </div>
          </div>
        </div>
      </a>
    ));
  };

  return (
    <>
      {/* Sidebar */}
      <aside className="sidebar bg-light">
        <div className="tab-content h-100" role="tablist">
          {/* Create */}
          <div className="tab-pane fade h-100" id="tab-content-create-chat" role="tabpanel">
            {/* ... other content */}
          </div>

          {/* Chats */}
          <div className="tab-pane fade h-100 show active" id="tab-content-chats" role="tabpanel">
            <div className="d-flex flex-column h-100 position-relative">
              <div className="hide-scrollbar">
                <div className="container py-8">
                  {/* Title */}
                  <div className="mb-8">
                    <h2 className="fw-bold m-0">Chats</h2>
                  </div>
                  {/* Search */}
                  <div className="mb-6">
                    <form action="#">
                      <div className="input-group">
                        <div className="input-group-text">
                          <div className="icon icon-lg">
                            {/* SVG Icon */}
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control form-control-lg ps-0"
                          placeholder="Search messages or users"
                          aria-label="Search for messages or users..."
                        />
                      </div>
                    </form>
                  </div>
                  <div className="card-list chat-list-container">
                    {isLoading ? (
                      renderPlaceholders(10) // Render placeholders while loading
                    ) : (
                      chats.map((room) => {
                        const otherParticipants = room.participants.filter(participant => participant.id !== user.id);
                        const participantNames = otherParticipants.slice(0, 2).map(participant => participant.name).join(', ');

                        return (
                          <a
                            key={room.id}
                            href={`/chats/${room.id}`}
                            className="card border-0 text-reset chat-link"
                            data-chat-id={room.id}
                          >
                            <div className="card-body">
                              <div className="row gx-5">
                                <div className="col-auto">
                                  {otherParticipants.length > 2 ? (
                                    <div className="avatar-group-trigon avatar-group-trigon-sm">
                                      {otherParticipants.slice(0, 3).map(participant => (
                                        participant.profile_photo ? (
                                          <div key={participant.id} className="avatar avatar-sm">
                                            <img className="avatar-img" src={`/assets/img/users/${participant.profile_photo}`} alt="#" />
                                          </div>
                                        ) : (
                                          <div key={participant.id} className="avatar avatar-sm">
                                            <span className="avatar-text bg-primary">{participant.name[0].toUpperCase()}</span>
                                          </div>
                                        )
                                      ))}
                                    </div>
                                  ) : (
                                    <div className="avatar">
                                      {otherParticipants[0].profile_photo ? (
                                        <img src={`/assets/img/users/${otherParticipants[0].profile_photo}`} alt="#" className="avatar-img" />
                                      ) : (
                                        <span className="avatar-text bg-primary">{otherParticipants[0].name[0].toUpperCase()}</span>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="col">
                                  <div className="d-flex align-items-center mb-3">
                                    <h5 className="me-auto mb-0">
                                      {participantNames}
                                      {otherParticipants.length > 2 && `, +${otherParticipants.length - 2}`}
                                    </h5>
                                    <span className="text-muted extra-small ms-2">{formatTimestamp(room.last_message_time)}</span>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <div className="line-clamp me-auto">
                                      {room.last_message}
                                    </div>
                                    {room.unread_count > 0 && (
                                      <div className="badge badge-circle bg-primary ms-5">
                                        <span>{room.unread_count}</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Notifications, Support, Settings */}
          {/* ... other content */}
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
